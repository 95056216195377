// ** React Imports
import { lazy } from 'react'

const PlanList = lazy(() => import('../../views/plan/list'))
const PlanAddForm = lazy(() => import('../../views/plan/add/index'))
const PlanUpdateForm = lazy(() => import('../../views/plan/edit/index'))

const PlanRoutes = [
  {
    element: <PlanList />,
    path: '/plan/list',
    meta: {
      action: 'manage',
      resource: 'Plan'
    }
  },
  {
    element: <PlanAddForm />,
    path: '/plan/add',
    meta: {
      action: 'create',
      resource: 'Plan'
    }
  },
  {
    element: <PlanUpdateForm />,
    path: '/plan/edit/:id',
    meta: {
      action: 'update',
      resource: 'Plan'
    }
  }
]

export default PlanRoutes
