// ** React Imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const UserList = lazy(() => import('../../views/user/list'))
const UserView = lazy(() => import('../../views/user/view/index'))
const UserAddForm = lazy(() => import('../../views/user/add/index'))
const UserUpdateForm = lazy(() => import('../../views/user/edit/index'))
const ChangePassword = lazy(() => import('../../views/user/view/ChangePassword'))

const UserRoutes = [
  {
    element: <UserList />,
    path: '/user/list',
    meta: {
      action: 'manage',
      resource: 'User'
    }
  },
  {
    element: <UserAddForm />,
    path: '/user/add',
    meta: {
      action: 'create',
      resource: 'User'
    }
  },
  {
    element: <UserView />,
    path: '/user/view/:id',
    meta: {
      action: 'read',
      resource: 'User'
    }
  },
  {
    element: <UserUpdateForm />,
    path: '/user/edit/:id',
    meta: {
      action: 'update',
      resource: 'User'
    }
  },
  {
    element: <ChangePassword />,
    path: '/user/change-password',
    meta: {
      action: 'manage',
      resource: 'Password'
    }
  }
]

export default UserRoutes
