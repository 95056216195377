// ** Icons Import
import { Check } from 'react-feather'
import { IoShield } from 'react-icons/io5'

export default [
  {
    id: 'roles-permissions',
    title: 'Roles & Permissions',
    icon: <IoShield size={20} />,
    children: [
      {
        id: 'roles',
        title: 'Roles',
        icon: <Check size={12} />,
        navLink: '/roles/list',
        resource: 'Role'
      },
      {
        id: 'permissions',
        title: 'Permissions',
        icon: <Check size={12} />,
        navLink: '/permissions/list',
        resource: 'Permission'
      }
    ]
  }
]
