// ** React Imports
import { lazy } from 'react'

const SubscriberList = lazy(() => import('../../views/subscriber/list'))
const SubscriberView = lazy(() => import('../../views/subscriber/view/index'))
const AdminSubscriberList = lazy(() => import('../../views/subscriber/admin-list'))
const AdminSubscriberView = lazy(() => import('../../views/subscriber/admin-view/index'))
const ImportUser = lazy(() => import('../../views/subscriber/upload-user/index'))
const ImportSubscriber = lazy(() => import('../../views/subscriber/upload-subscribers/index'))

const SubscriberRoutes = [
  {
    element: <SubscriberList />,
    path: '/subscriber/list',
    meta: {
      action: 'manage',
      resource: 'Subscriber'
    }
  },
  {
    element: <SubscriberView />,
    path: '/subscriber/view/:id',
    meta: {
      action: 'read',
      resource: 'Subscriber'
    }
  },
  {
    element: <AdminSubscriberList />,
    path: '/admin-subscriber/list',
    meta: {
      action: 'manage',
      resource: 'Subscriber'
    }
  },
  {
    element: <AdminSubscriberView />,
    path: '/admin-subscriber/view/:id',
    meta: {
      action: 'read',
      resource: 'Subscriber'
    }
  },
  {
    element: <ImportUser />,
    path: '/subscriber/upload',
    meta: {
      action: 'create',
      resource: 'Subscriber'
    }
  },
  {
    element: <ImportSubscriber />,
    path: '/media-house-subscriber/upload',
    meta: {
      action: 'create',
      resource: 'Subscriber'
    }
  }
]

export default SubscriberRoutes
