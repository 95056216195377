// ** React Imports
import { lazy } from 'react'

const Roles = lazy(() => import('../../views/role/list/index'))
const RoleAddForm = lazy(() => import('../../views/role/add/index'))
const RoleUpdateForm = lazy(() => import('../../views/role/edit/index'))
const Permissions = lazy(() => import('../../views/permission/list/index'))
const PermissionAddForm = lazy(() => import('../../views/permission/add/index'))
const PermissionUpdateForm = lazy(() => import('../../views/permission/edit/index'))

const RolePermissionRoutes = [
  {
    element: <Roles />,
    path: '/roles/list',
    meta: {
      action: 'manage',
      resource: 'Role'
    }
  },
  {
    path: '/roles/add',
    element: <RoleAddForm />,
    meta: {
      action: 'create',
      resource: 'Role'
    }
  },
  {
    path: '/roles/edit/:id',
    element: <RoleUpdateForm />,
    meta: {
      action: 'update',
      resource: 'Role'
    }
  },
  {
    element: <Permissions />,
    path: '/permissions/list',
    meta: {
      action: 'manage',
      resource: 'Permission'
    }
  },
  {
    element: <PermissionAddForm />,
    path: '/permissions/add',
    meta: {
      action: 'create',
      resource: 'Permission'
    }
  },
  {
    element: <PermissionUpdateForm />,
    path: '/permissions/edit/:id',
    meta: {
      action: 'update',
      resource: 'Permission'
    }
  }
]

export default RolePermissionRoutes
